


















































































import Vue, { PropType } from "vue";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";
import ServiceStatus from "@zubut/common/src/constants/services/status";
import { format } from "@zubut/common/src/utils/time";
import ReservationService from "@zubut/common/src/models/reservations/reservation-service";
import AssignServiceDropdown from "./AssignServiceDropdown/AssignServiceDropdown.vue";
import ServiceActions from "@/app/views/ServiceCenter/ServiceActions.vue";
import { differenceInHours, differenceInMinutes, parseISO } from "date-fns";

export default Vue.extend({
  name: "ReservationCard",

  components: {
    AssignServiceDropdown,
    ServiceActions
  },

  props: {
    reservationService: {
      type: Object as PropType<ReservationService>,
      required: true
    },
    moduleName: {
      type: String,
      default: "serviceCenter/reservations"
    }
  },

  computed: {
    shortId(): string {
      return `ID-${makeShortUUID(this.reservationService.id)}`;
    },
    isDelayedReservation(): boolean {
      return (
        !this.isFinished &&
        !this.isOnRoute &&
        differenceInMinutes(
          parseISO(this.reservationService.start),
          Date.now()
        ) < 0
      );
    },
    isReviewPending(): boolean {
      return (
        this.reservationService.status === ServiceStatus.NUM_REVIEW_PENDING
      );
    },
    isFinished(): boolean {
      return ServiceStatus.isCompletedStatus(this.reservationService.status);
    },
    isOnRoute(): boolean {
      return ServiceStatus.isOnRouteStatus(this.reservationService.status);
    },
    hasAdditionalHours(): boolean {
      return this.reservationService?.additionalHours &&
        this.reservationService?.additionalHoursStatus ===
          AdditionalHours.NUM_PENDING_HOURS
        ? true
        : false;
    },
    showAssignDriver(): boolean {
      return (
        !this.reservationService.driverId &&
        this.reservationService.status === ServiceStatus.NUM_CREATED
      );
    },
    hoursDiff(): number {
      const hoursDiff = differenceInHours(
        parseISO(this.reservationService.end),
        Date.now()
      );
      return hoursDiff;
    },
    timeEnded(): boolean {
      return (
        differenceInMinutes(
          parseISO(this.reservationService.end),
          Date.now()
        ) <= 0
      );
    },
    currentTimeMessage(): string {
      if (this.reservationService.status === ServiceStatus.NUM_CREATED) {
        return `Comienza ${format(
          parseISO(this.reservationService.start),
          "h:mm a"
        )}`;
      }

      if (
        [ServiceStatus.NUM_ACTIVE, ServiceStatus.NUM_REVIEW_PENDING].includes(
          this.reservationService.status
        )
      ) {
        if (this.hoursDiff > 0) {
          return `${this.hoursDiff === 1 ? "Resta" : "Restan"} ${
            this.hoursDiff
          } ${this.hoursDiff === 1 ? "hr" : "hrs"}`;
        } else {
          return `Finaliza ${format(
            parseISO(this.reservationService.end),
            "h:mm a"
          )}`;
        }
      }

      return "";
    },
    actionsMenuVariant(): string {
      if (
        this.isDelayedReservation ||
        this.isReviewPending ||
        this.hasAdditionalHours
      ) {
        return "white";
      }
      return "dark";
    },
    iconVariant(): string {
      return this.hasAdditionalHours || this.isDelayedReservation
        ? "white"
        : "normal";
    }
  },

  methods: {
    reviewAdditionalHours() {
      this.$store
        .dispatch(`${this.moduleName}/reviewAdditionalHours`, {
          reservationBlockId: this.reservationService.reservationBlockId,
          decision: true,
          serviceId: this.reservationService.id
        })
        .then(() => {
          this.$emit("extra-hours-accepted");
        });
    }
  }
});
