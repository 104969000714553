const SUPER_ADMIN = "Super administrador";
const OPERATIONS = "Operaciones";
const ACCOUNTING = "Contabilidad";
const SALES = "Ventas";
const MARKETING = "Mercadotecnia";

const NUM_SUPER_ADMIN = 0;
const NUM_OPERATIONS = 1;
const NUM_ACCOUNTING = 2;
const NUM_SALES = 3;
const NUM_MARKETING = 4;

const options = [
  { value: NUM_SUPER_ADMIN, text: SUPER_ADMIN },
  { value: NUM_OPERATIONS, text: OPERATIONS },
  { value: NUM_ACCOUNTING, text: ACCOUNTING },
  { value: NUM_SALES, text: SALES },
  { value: NUM_MARKETING, text: MARKETING }
];

const get = {};
get[NUM_SUPER_ADMIN] = SUPER_ADMIN;
get[NUM_OPERATIONS] = OPERATIONS;
get[NUM_ACCOUNTING] = ACCOUNTING;
get[NUM_SALES] = SALES;
get[NUM_MARKETING] = MARKETING;

export default {
  get,
  options,
  SUPER_ADMIN,
  OPERATIONS,
  ACCOUNTING,
  SALES,
  MARKETING,
  NUM_SUPER_ADMIN,
  NUM_OPERATIONS,
  NUM_ACCOUNTING,
  NUM_SALES,
  NUM_MARKETING
};
