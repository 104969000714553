<template>
  <div class="d-flex mr-3">
    <z-button
      v-tooltip.top="'Ver columnas'"
      :variant="isKanbanLayout ? 'primary' : 'secondary'"
      fit="auto"
      @click="$emit('update:layoutType', LayoutType.NUM_KANBAN)"
    >
      <z-icon
        name="Kanban"
        :color="isKanbanLayout ? 'white' : '#0076FF'"
        size="12"
      />
    </z-button>
    <z-button
      v-tooltip.top="'Ver lista'"
      :variant="isTableLayout ? 'primary' : 'secondary'"
      fit="auto"
      @click="$emit('update:layoutType', LayoutType.NUM_TABLE)"
    >
      <z-icon
        name="List"
        :color="isTableLayout ? 'white' : '#0076FF'"
        size="12"
      />
    </z-button>
  </div>
</template>

<script>
import LayoutType from "@/constants/serviceCenter/layout-type";

export default {
  name: "LayoutSwtich",

  props: {
    layoutType: {
      type: Number,
      default: LayoutType.NUM_TABLE
    }
  },

  data() {
    return {
      LayoutType
    };
  },

  computed: {
    isKanbanLayout() {
      return this.layoutType === LayoutType.NUM_KANBAN;
    },
    isTableLayout() {
      return this.layoutType === LayoutType.NUM_TABLE;
    }
  }
};
</script>
