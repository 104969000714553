<template>
  <div>
    <quick-message
      class="mb-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeOfMessage"
    />
    <z-kanban
      :loading="loading"
      :columns="reservations"
      :move-callback="handleCardMove"
    >
      <template #title="{ title }">
        <!-- Erase button for completed column -->
        <div
          v-if="title === 'Completado'"
          class="d-flex w-100 justify-content-between align-items-center"
        >
          <div>
            {{ title }}
          </div>
          <div v-show="hasCompletedReservations">
            <b-dropdown
              class="service-action"
              menu-class="service-action-dropdown"
              variant="link"
              size="md"
              no-caret
              dropleft
              lazy
            >
              <template v-slot:button-content>
                <font-awesome-icon icon="ellipsis-h" />
              </template>
              <b-dropdown-item @click="cleanCompletedReservations">
                <div class="d-flex align-items-center">
                  <z-icon name="Trash" size="18" class="mr-2" />
                  Limpiar
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <template v-else>
          {{ title }}
        </template>
      </template>
      <template #item="{ data: reservation }">
        <reservation-card
          :key="reservation.id"
          :reservation-service="reservation"
          class="mb-3 cursor-move"
          @click="handleItemClick(reservation)"
          @cancel-service="$emit('cancel-service', $event)"
          @finish-service="$emit('finish-service', $event)"
          @check-in="$emit('check-in', $event)"
          @extra-hours-accepted="handleAcceptedHours"
        ></reservation-card>
      </template>
    </z-kanban>
    <div class="text-sm text-dim-gray text-center mt-3 -mb-3">
      Solo se muestran las reservaciones del día
    </div>
  </div>
</template>

<script>
import ReservationCard from "@/app/components/ReservationCard";
import ZKanban from "@zubut/common/src/components/ZKanban";

const moduleName = "serviceCenter/reservations";

export default {
  name: "ReservationsKanban",

  components: {
    ReservationCard,
    ZKanban
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false
    };
  },

  computed: {
    reservations() {
      return this.$store.getters[`${moduleName}/getKanbanReservations`];
    },
    hasCompletedReservations() {
      return this.$store.getters[`${moduleName}/hasCompletedReservations`];
    },
    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    }
  },

  methods: {
    handleItemClick(reservationService) {
      this.$emit("open-detail", reservationService);
    },
    cleanCompletedReservations() {
      this.$store.dispatch(`${moduleName}/cleanCompletedReservations`);
    },
    handleCardMove() {
      // Don't allow to drop card in oder columns
      return false;
    },
    handleAcceptedHours() {
      this.message = "Horas extras aceptadas";
      this.isSuccessful = true;
      this.typeOfMessage = "success";
    }
  }
};
</script>

<style lang="scss" scoped>
.-mb-3 {
  margin-bottom: -1em;
}
::v-deep .service-action {
  .dropdown-toggle {
    padding: 0 1em !important;
    margin-right: -1em;
  }
  .service-action-dropdown {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    border-radius: 6px;
    .dropdown-item {
      font-size: 12px;
      background-color: $white;
      color: $charcoal;
      padding: 0.8em 1em;

      &:hover {
        background-color: rgba($royal-blue, 0.06);
      }
    }
  }
}
</style>
