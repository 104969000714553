<template>
  <div>
    <z-table
      hover
      primary-key="id"
      :items="reservations"
      :fields="fields"
      :busy="loading"
      @row-clicked="handleRowClick"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <!-- slot - empty message -->
      <template v-slot:empty>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay servicios de renta por hora activos
        </p>
      </template>
      <!-- slot - header - vehicleType -->
      <template v-slot:head(vehicleType)>
        <div>
          <div class="d-lg-none">
            Vehículo
          </div>
          <z-table-header-dropdown
            id="dropdown-vehicle-type"
            v-model="filterVehicleType"
            class="d-none d-lg-block"
            button-class="text-center"
            :options="vehicleTypeOptions"
          />
        </div>
      </template>
      <!-- slot - header - chekin -->
      <template v-slot:head(start)>
        <div>
          <div class="d-lg-none">
            Check in
          </div>
          <z-table-header-dropdown
            id="dropdown-checkin"
            v-model="filterDate"
            class="d-none d-lg-block"
            :options="dateOptions"
            name="Check in"
          />
        </div>
      </template>
      <!--  slot - header - status -->
      <template v-slot:head(status)>
        <div>
          <div class="d-lg-none">
            Estado
          </div>
          <z-table-header-dropdown
            id="dropdown-status"
            v-model="filterStatus"
            class="d-none d-lg-block"
            :options="statusOptions"
            name="Estado"
          />
        </div>
      </template>
      <!-- slot - alert data -->
      <template v-slot:cell(alert)="data">
        <z-table-alert
          :driver-id="data.item.driverId"
          :has-incidence="data.item.hasIncidence"
        />
      </template>
      <!-- slot - driver data -->
      <template v-slot:cell(driver)="row">
        <assign-service-dropdown
          :service-id="row.item.id"
          :current-driver="row.item.driverId"
          :current-driver-name="row.item.driver"
          module-name="serviceCenter/reservations"
          @success="handleAssignation"
          @error="handleError"
        />
      </template>
      <!-- slot - status data -->
      <template v-slot:cell(status)="row">
        <status-service-reservation
          :status="row.item.status"
          :driver-id="row.item.driverId"
          :start="row.item.start"
        />
      </template>
      <!-- slot - vehicle type data -->
      <template v-slot:cell(vehicleType)="data">
        <span>
          <font-awesome-icon
            class="vehicle"
            :icon="vehicle(data.value)"
            size="2x"
          />
        </span>
      </template>
      <!-- slot - hours data -->
      <template v-slot:cell(hours)="{ item: service }">
        <div class="d-flex align-items-start">
          {{ totalHours(service.start, service.end) }}
          <status-badge
            v-if="hasAdditionalHours(service)"
            class="ml-1"
            :status-name="
              service.additionalHours === 1
                ? '+ ' + service.additionalHours + ' hr'
                : '+ ' + service.additionalHours + ' hrs'
            "
            type="yellow"
          />
        </div>
      </template>
      <!--slot - actions -->
      <template v-slot:cell(actions)="{ item: service }">
        <service-actions
          :id="service.id"
          :status="service.status"
          :archived="service.archived"
          :payment-status="service.paymentStatus"
          :type-service="service.type"
          :driver-id="service.driverId"
          @cancel-service="$emit('cancel-service', service)"
          @finish-service="$emit('finish-service', service)"
          @check-in="$emit('check-in', service)"
        />
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="totalReservations"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import ServiceAccess from "@/constants/access/services";
import { diffInHours, formatISO } from "@zubut/common/src/utils/time";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehicleType from "@/constants/vehicles/type";
import DriverVehicles from "@/constants/drivers/vehicles";
import DateConstants from "@zubut/common/src/constants/filters/date";
import ReservationStatus from "@/constants/serviceCenter/status-reservation";
import AdditionalHours from "@zubut/common/src/constants/services/additional-hours";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import StatusServiceReservation from "@/app/components/StatusServiceReservation";
import ZTableAlert from "@/app/components/ZTableAlert";
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import * as mutation from "@/store/modules/serviceCenter/reservations/mutations-types.js";
import ServiceActions from "../ServiceActions";
import AssignServiceDropdown from "@/app/components/AssignServiceDropdown/AssignServiceDropdown";
import _debounce from "lodash/debounce";
import LayoutType from "@/constants/serviceCenter/layout-type";
import filtering from "@/mixins/filtering";

const moduleName = "serviceCenter/reservations";

export default {
  name: "ReservationsTable",

  components: {
    AssignServiceDropdown,
    ServiceActions,
    StatusBadge,
    StatusServiceReservation,
    ZTable,
    ZTableAlert,
    ZTableHeaderDropdown,
    ZTablePagination
  },

  mixins: [filtering("serviceCenter")],

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      serviceAccess: ServiceAccess,
      serviceType: ServiceType,
      showConfirmationModal: false,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      selectedService: null,
      fields: [
        {
          key: "alert",
          label: ""
        },
        {
          key: "id",
          label: "ID",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "start",
          label: "Check in",
          formatter: val =>
            val ? formatISO(val, "dd 'de' MMM yyyy p aaa") : ""
        },
        { key: "user", label: "Sucursal" },
        { key: "driver", label: "Mensajero" },
        { key: "status", label: "Estado" },
        {
          key: "vehicleType",
          label: "",
          class: "text-center"
        },
        {
          key: "hours",
          label: "Hrs. reservadas",
          class: "text-left"
        },
        {
          key: "actions",
          label: "Acciones",
          class: "d-none d-md-table-cell text-right"
        }
      ],
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        DriverVehicles.options
      ),
      dateOptions: [
        { text: "Todos", value: null },
        { value: DateConstants.NUM_TODAY, text: DateConstants.TODAY },
        { value: DateConstants.NUM_TOMORROW, text: DateConstants.TOMORROW },
        { value: DateConstants.NUM_THIS_WEEK, text: DateConstants.THIS_WEEK }
      ],
      statusOptions: [{ text: "Todos", value: null }].concat(
        ReservationStatus.options
      ),
      LayoutType
    };
  },

  computed: {
    reservations() {
      return this.$store.getters[`${moduleName}/getReservations`];
    },
    stateFilters() {
      return this.$store.getters[`${moduleName}/getFilters`];
    },
    totalReservations() {
      return this.$store.getters[`${moduleName}/getTotalItems`];
    },
    clientsOptions() {
      return this.$store.getters["clients/clientsOptions"];
    },
    selectedServiceId() {
      if (this.selectedService && this.selectedService.id) {
        return makeShortUUID(this.selectedService.id);
      }
      return "";
    },

    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    },
    searchText: {
      get() {
        return this.stateFilters.search;
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_SEARCH}`, val);
      }
    },
    filterDate: {
      get() {
        return this.stateFilters.date;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_DATE}`,
          val
        );
      }
    },
    filterClient: {
      get() {
        return this.stateFilters.clientId;
      },
      set(val) {
        this.setFilter("client", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_CLIENT}`, val);
      }
    },
    filterVehicleType: {
      get() {
        return this.stateFilters.vehicleType;
      },
      set(val) {
        this.setFilter("vehicleType", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_VEHICLE_TYPE}`,
          val
        );
      }
    },
    filterSortBy: {
      get() {
        return this.stateFilters.sortBy;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_BY}`,
          val
        );
      }
    },
    filterSortOrder: {
      get() {
        return this.stateFilters.sortOrder;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_ORDER}`,
          val
        );
      }
    },
    filterStatus: {
      get() {
        return this.stateFilters.status;
      },
      set(val) {
        this.setFilter("status", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_STATUS}`,
          val
        );
      }
    },
    filterLayoutType: {
      get() {
        return this.$store.getters[`${moduleName}/getLayoutType`];
      },
      set(val) {
        this.setFilter("layout", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_LAYOUT_TYPE}`,
          val
        );
      }
    },
    hasUpdates: {
      get() {
        return this.$store.getters[`${moduleName}/getHasUpdates`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.TOGGLE_UPDATE_INDICATOR}`,
          val
        );
      }
    }
  },

  mounted() {
    this.hasUpdates = false;
  },

  methods: {
    vehicle(type) {
      switch (type) {
        case VehicleType.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleType.NUM_CAR:
          return "zubut-car";
        case VehicleType.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    totalHours(start, end) {
      const diff = diffInHours(start, end);
      return `${diff} hrs`;
    },

    showCancelServiceModal(service) {
      this.selectedService = service;
      this.showConfirmationModal = true;
    },

    hideModal() {
      this.showConfirmationModal = false;
    },

    paginationReset() {
      this.currentPage = 1;
    },

    handleRowClick(service) {
      this.$emit("open-detail", service);
    },

    handleAssignation(res) {
      this.message = res?.data?.message || "Servicio se asignó con éxito";
      this.isSuccessful = true;
      this.typeOfMessage = "success";
    },

    handleError(err) {
      this.message =
        err?.message || "Ocurrió un error al intentar finalizar el servicio";
      this.hasError = true;
      this.typeOfMessage = "error";
    },

    hasAdditionalHours(service) {
      return (
        service.additionalHours &&
        service.additionalHoursStatus === AdditionalHours.NUM_PENDING_HOURS
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.container-reservation-service {
  .vehicle {
    font-size: 24px;
  }
}
</style>
