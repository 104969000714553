<template>
  <div
    class="d-flex flex-column flex-md-row justify-content-md-between mb-md-4"
  >
    <div class="mb-3 mb-md-0">
      <span class="card-title"> Centro de entregas </span>
      <div class="d-md-block header-text">
        {{ user }}
      </div>
    </div>
    <div class="d-md-flex">
      <div v-if="isNotGuias" class="pb-md-2">
        <z-dropdown-cities class="mr-3 w-100 h-100 d-flex" />
      </div>
      <div v-if="isNotGuias" class="d-none d-md-block limit"></div>
      <div>
        <z-button
          v-if="$can(servicesAccess.CREATE_SERVICE, servicesAccess.moduleName)"
          id="create-service-btn"
          v-b-modal.service-creation-modal
          class="create-service-button mt-3 mt-md-0"
          w-100
          expanded
          size="lg"
        >
          Crear servicio
        </z-button>
      </div>
    </div>
    <service-creation-modal />
  </div>
</template>

<script>
import ZDropdownCities from "@/app/components/ZDropdownCities.vue";
import ServiceCreationModal from "@/app/views/ServiceCreation/ServiceCreationModal";
import ServicesAccess from "@/constants/access/services";

export default {
  name: "ServiceCenter",

  components: {
    ServiceCreationModal,
    ZDropdownCities
  },

  props: {
    user: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      servicesAccess: ServicesAccess
    };
  },

  computed: {
    cityOptions() {
      return this.$store.getters["cities/cityOptions"];
    },
    isNotGuias() {
      return this.$route.query.tab !== "guias";
    }
  },

  created() {
    this.getCities();
  },

  methods: {
    getCities() {
      this.$store.dispatch("cities/getCities");
    }
  }
};
</script>

<style lang="scss" scoped>
.header-text {
  color: $dim-gray;
  font-size: 12px;
}

.limit {
  height: 3em;
  margin-right: 2em;
  margin-left: 2em;
  border-left: 1px solid $gainsboro;
}
</style>
