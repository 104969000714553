<template>
  <div class="container-multipoint-service">
    <active-services-header
      class="mt-4"
      :title="serviceType.MULTI_POINT"
      :service-type="serviceType.NUM_MULTI_POINT"
      :loading.sync="loading"
      :has-updates.sync="hasUpdates"
      @refresh="getListingAndResetPagination"
    />
    <b-row class="mt-4">
      <b-col lg="3">
        <z-search-input
          v-model="searchText"
          placeholder="Busca por cliente, mensajero o ID"
        />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown-check-list-clients v-model="selectedClient" />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-date"
          v-model="filterStatus"
          :options="statusOptions"
          name="Estado"
        />
      </b-col>
      <b-col lg="2" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-date"
          v-model="filterVehicleType"
          :options="vehicleTypeOptions"
          name="Vehículo"
        />
      </b-col>
    </b-row>
    <div class="mt-4">
      <quick-message
        class="mb-2"
        with-icon
        :show.sync="hasMessage"
        :message="message"
        :type="typeOfMessage"
      />
      <z-table
        hover
        primary-key="id"
        :items="services"
        :fields="fields"
        :busy="loading"
        @row-clicked="viewServiceDetail"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <loading-spinner />
          </div>
        </template>
        <!-- slot - empty message -->
        <template v-slot:empty>
          <p class="text-center py-5 my-5 font-weight-semi-bold">
            No hay servicios express activos
          </p>
        </template>
        <!-- slot - header - chekin -->
        <template v-slot:head(createdAt)>
          <z-table-head-sort :order.sync="filterSortOrder" class="header-date">
            Fecha
          </z-table-head-sort>
        </template>
        <!--  slot - header - status -->
        <template v-slot:head(status)>
          <div>
            <div class="d-lg-none">Estado</div>
            <z-table-header-dropdown
              id="dropdown-status"
              v-model="filterStatus"
              class="d-none d-lg-block"
              :options="statusOptions"
              name="Estado"
            />
          </div>
        </template>
        <!-- slot - header - vehicleType -->
        <template v-slot:head(vehicleType)>
          <div>
            <div class="d-lg-none">Vehículo</div>
            <z-table-header-dropdown
              id="dropdown-vehicle-type"
              v-model="filterVehicleType"
              class="d-none d-lg-block"
              button-class="text-center"
              :options="vehicleTypeOptions"
            />
          </div>
        </template>
        <!-- slot - alert data -->
        <template v-slot:cell(alert)="data">
          <z-table-alert
            :driver-id="data.item.driverId"
            :has-incidence="data.item.hasIncidence"
          />
        </template>
        <!-- slot - driver data -->
        <template v-slot:cell(driver)="row">
          <assign-service-dropdown
            :service-id="row.item.id"
            :current-driver="row.item.driverId"
            :current-driver-name="row.item.driver"
            module-name="serviceCenter/multipoint"
            @success="handleAssignation"
            @error="handleError"
          />
        </template>
        <!-- slot - status data -->
        <template v-slot:cell(status)="row">
          <status-service-multipoint
            :status="row.item.status"
            :driver-id="row.item.driverId"
            :progress-id="row.item.progressId"
          />
        </template>
        <!-- slot - status data -->
        <template v-slot:cell(destinationNo)="row">
          <span>
            {{ row.item.destinationsDeliveredTo }} de
            {{ row.item.destinationNo }}
          </span>
        </template>
        <!-- slot - vehicle type data -->
        <template v-slot:cell(vehicleType)="data">
          <span>
            <font-awesome-icon
              class="vehicle"
              :icon="vehicle(data.value)"
              size="2x"
            />
          </span>
        </template>
        <!--slot - actions -->
        <template v-slot:cell(actions)="{ item: service }">
          <service-actions
            :id="service.id"
            :status="service.status"
            :archived="service.archived"
            :payment-status="service.paymentStatus"
            :type-service="service.type"
            :driver-id="service.driverId"
            @cancel-service="showCancelServiceModal(service)"
            @finish-service="dispatchComplete(service)"
            @archive="dispatchArchive(service)"
          >
            <template v-slot:extra-option>
              <b-dropdown-item
                v-if="hasExtraOption(service)"
                @click="() => handleNextAction(service)"
              >
                {{ `Avanzar a ${optionName(service.progressId)}` }}
              </b-dropdown-item>
            </template>
          </service-actions>
        </template>
      </z-table>
      <z-table-pagination
        :total-rows="totalServices"
        :per-page.sync="perPage"
        :current-page.sync="currentPage"
      />
    </div>
    <confirmation-dialog
      :show="showConfirmationModal"
      @accept="dispatchCancel()"
      @cancel="hideModal()"
      @hidden="hideModal()"
    >
      ¿Seguro que deseas cancelar el servicio
      <b>{{ selectedServiceId }}</b> ?
    </confirmation-dialog>
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";
import { formatMoney } from "@/utils/money";
import ServiceAccess from "@/constants/access/services";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehicleType from "@/constants/vehicles/type";
import DriverVehicles from "@/constants/drivers/vehicles";
import ServiceStatus from "@/constants/services/status";
import MultipointStatus from "@/constants/serviceCenter/status-multipoint";
import Progress from "@/constants/serviceCenter/progress";
import Services from "@/services/services";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import StatusServiceMultipoint from "@/app/components/StatusServiceMultipoint";
import ZTableAlert from "@/app/components/ZTableAlert";
import filtering from "@/mixins/filtering";
import * as mutation from "@/store/modules/serviceCenter/multipoint/mutations-types.js";
import ActiveServicesHeader from "./ActiveServicesHeader";
import ServiceActions from "./ServiceActions";
import AssignServiceDropdown from "@/app/components/AssignServiceDropdown/AssignServiceDropdown";
import _debounce from "lodash/debounce";

const moduleName = "serviceCenter/multipoint";

export default {
  name: "MultipointServices",

  components: {
    ActiveServicesHeader,
    AssignServiceDropdown,
    ServiceActions,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination,
    StatusServiceMultipoint,
    ZDropdown,
    ZDropdownCheckListClients,
    ZSearchInput,
    ZTableAlert,
    ZTableHeadSort,
    ConfirmationDialog
  },

  mixins: [filtering("serviceCenter")],

  data() {
    return {
      serviceType: ServiceType,
      loading: false,
      showConfirmationModal: false,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      selectedService: null,
      fields: [
        {
          key: "alert",
          label: ""
        },
        {
          key: "id",
          label: "ID",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val =>
            val ? formatISO(val, "dd 'de' MMM yyyy p aaa") : ""
        },
        { key: "user", label: "Cliente" },
        { key: "driver", label: "Mensajero" },
        { key: "status", label: "Estado" },

        {
          key: "destinationNo",
          label: "Destinos",
          class: "text-center"
        },
        {
          key: "vehicleType",
          label: "",
          class: "text-center"
        },
        {
          key: "cost",
          label: "Costo",
          class: "text-center",
          formatter: value => formatMoney(value)
        },
        {
          key: "actions",
          label: "Acciones",
          class: "d-none d-md-table-cell text-right"
        }
      ],
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        DriverVehicles.options
      ),
      statusOptions: [{ text: "Todos", value: null }].concat(
        MultipointStatus.options
      )
    };
  },

  computed: {
    services() {
      return this.$store.getters[`${moduleName}/getServices`];
    },
    stateFilters() {
      return this.$store.getters[`${moduleName}/getFilters`];
    },
    totalServices() {
      return this.$store.getters[`${moduleName}/getTotalItems`];
    },
    clientsOptions() {
      return this.$store.getters["clients/clientsOptions"];
    },
    selectedServiceId() {
      if (this.selectedService && this.selectedService.id) {
        return makeShortUUID(this.selectedService.id);
      }
      return "";
    },

    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    },
    selectedClient: {
      get() {
        return this.stateFilters.clientId;
      },
      set(val) {
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_CLIENT}`, val);
      }
    },
    filterVehicleType: {
      get() {
        return this.stateFilters.vehicleType;
      },
      set(val) {
        this.setFilter("vehicleType", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_VEHICLE_TYPE}`,
          val
        );
      }
    },
    filterSortBy: {
      get() {
        return this.stateFilters.sortBy;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_BY}`,
          val
        );
      }
    },
    filterSortOrder: {
      get() {
        return this.stateFilters.sortOrder;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_ORDER}`,
          val
        );
      }
    },
    filterStatus: {
      get() {
        return this.stateFilters.status;
      },
      set(val) {
        this.setFilter("status", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_STATUS}`,
          val
        );
      }
    },
    selectedCity() {
      return this.$store.getters["cities/getCity"];
    },
    hasUpdates: {
      get() {
        return this.$store.getters[`${moduleName}/getHasUpdates`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.TOGGLE_UPDATE_INDICATOR}`,
          val
        );
      }
    },
    searchText: {
      get() {
        return this.stateFilters.search;
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_SEARCH}`, val);
      }
    }
  },

  watch: {
    selectedCity: "getListingAndResetPagination",
    selectedClient: "getServices"
  },

  created() {
    this.setRouteFilters();
    this.loading = true;
    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getServices(), 100);
    this.searchFilterListener = _debounce(() => {
      this.getServices();
    }, 600);
  },

  mounted() {
    this.hasUpdates = false;
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab || "multi-punto";
      const page = this.currentPage;
      const show = this.perPage;
      const search = this.searchText;
      const status = this.filterStatus;
      const vehicleType = this.filterVehicleType;

      query.tab = query.tab ? query.tab : tab;
      query.search = query.search ? query.search : search;
      query.status = query.status || !status ? query.status : status;
      query.vehicleType = query.vehicleType
        ? query.vehicleType
        : vehicleType || query.vehicleType;
      query.page = query.page ? query.page : page;
      query.show = query.show ? query.show : show;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});

      this.searchText = query.search ?? "";
      this.filterStatus = query.status ?? null;
      this.filterVehicleType = query.vehicleType ?? null;
    },

    getListingAndResetPagination() {
      this.paginationReset();
      this.getServices();
    },

    vehicle(type) {
      switch (type) {
        case VehicleType.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleType.NUM_CAR:
          return "zubut-car";
        case VehicleType.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    buildRequest() {
      const filter = {
        ...this.pagination,
        order: `${this.filterSortBy} ${this.filterSortOrder}`
      };

      const where = { like: this.searchText, archived: false };

      /* Set client filter */
      if (this.selectedClient.length > 0) {
        where.clientId = { inq: this.selectedClient };
      }

      /* Set vehicle type filter */
      if (this.filterVehicleType !== null) {
        where.vehicleType = this.filterVehicleType;
      }

      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }

      where.status = [
        ServiceStatus.NUM_CREATED,
        ServiceStatus.NUM_REQUESTING,
        ServiceStatus.NUM_ACTIVE,
        ServiceStatus.NUM_SCHEDULED,
        ServiceStatus.NUM_DRIVERS_NOT_AVAILABLE
      ];

      /* Set status type filter */
      if (this.filterStatus !== null) {
        if (this.filterStatus === MultipointStatus.NUM_WITHOUT_DRIVER) {
          where.driversId = { eq: null };
        }
        if (this.filterStatus === MultipointStatus.NUM_ACCEPTED) {
          where.status = [ServiceStatus.NUM_ACTIVE];
        }
        if (this.filterStatus === MultipointStatus.NUM_SCHEDULED) {
          where.status = [ServiceStatus.NUM_SCHEDULED];
        }
      }

      where.type = [
        ServiceType.NUM_SIMPLE,
        ServiceType.NUM_MULTI_POINT,
        ServiceType.NUM_DYNAMIC
      ];

      return { filter, where };
    },

    getServices() {
      if (this.$route.name === "serviceCenter") {
        const { filter, where } = this.buildRequest();
        this.loading = true;
        this.$store
          .dispatch(`${moduleName}/getServices`, { filter, where })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    dispatchCancel() {
      this.hideModal();
      this.loading = true;
      const service = this.selectedService;
      const cancel = {
        id: service.id,
        clientId: service.clientId,
        reason: 0
      };
      this.$store
        .dispatch(`${moduleName}/cancelService`, cancel)
        .then(res => {
          this.successMessage(
            res?.response?.message || "Servicio cancelado correctamente"
          );
        })
        .catch(err => {
          this.errorMessage(err, "Error al cancelar el servicio");
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dispatchComplete(service) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/completeService`, service.id)
        .then(res => {
          this.successMessage(
            res?.data?.message || "Servicio finalizado con exito"
          );
        })
        .catch(err => {
          this.errorMessage(
            err,
            "Occurrió un error al intentar finalizar el servicio"
          );
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dispatchArchive(service) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/archiveService`, service.id)
        .then(res => {
          this.successMessage("Servicio archivado con éxito");
        })
        .catch(err => {
          this.errorMessage(
            err,
            "Occurrió un error al intentar archivar el servicio"
          );
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showCancelServiceModal(service) {
      this.selectedService = service;
      this.showConfirmationModal = true;
    },

    hideModal() {
      this.showConfirmationModal = false;
    },

    paginationReset() {
      this.currentPage = 1;
    },

    viewServiceDetail(service) {
      if (
        this.$can(ServiceAccess.READ_SERVICE_DETAIL, ServiceAccess.moduleName)
      ) {
        this.$router.push({
          name: "serviceCenter-serviceDetail",
          params: { id: service.id }
        });
      }
    },

    successMessage(message) {
      this.message = message;
      this.isSuccessful = true;
      this.typeOfMessage = "success";
    },

    errorMessage(err, fallback) {
      this.message = err?.message || fallback;
      this.hasError = true;
      this.typeOfMessage = "error";
    },

    handleAssignation(res) {
      this.successMessage("Servicio asignado con éxito");
    },

    handleError(err) {
      this.errorMessage(
        err,
        "Ocurrió un error al intentar asignar el servicio"
      );
    },

    hasExtraOption(service) {
      if (
        [
          Progress.NUM_ACCEPTED,
          Progress.NUM_IN_COLLECTION,
          Progress.NUM_IN_ROUTE,
          Progress.NUM_IN_ROUTE_TO_NEXT_POINT,
          Progress.NUM_IN_DELIVERY
        ].includes(service.progressId) &&
        service.driver
      ) {
        return service.destinationsDeliveredTo < service.destinationNo;
      }
      return false;
    },

    optionName(progress) {
      switch (progress) {
        case Progress.NUM_ACCEPTED:
          return "Recolectado";
        case Progress.NUM_IN_COLLECTION:
          return "En Ruta";
        case Progress.NUM_IN_ROUTE:
          return "Entregado";
        case Progress.NUM_IN_ROUTE_TO_NEXT_POINT:
          return "Entregado";
        case Progress.NUM_IN_DELIVERY:
          return "Entregado";
        default:
          return "Recolectado";
      }
    },

    destinationArrived(service) {
      const data = {};
      data.serviceId = service.id;
      if (
        service.destinations &&
        service.destinationNo < service.destinations.length
      ) {
        if (
          service.progressId === Progress.NUM_IN_ROUTE ||
          service.progressId === Progress.NUM_IN_ROUTE_TO_NEXT_POINT
        ) {
          service.destinationsDeliveredTo++;
        }
        const dest = service.destinations.find(
          d => d.order === service.destinationsDeliveredTo
        );
        data.destinationId = dest ? dest.id : null;
      }
      return Services.destinationArrived(data)
        .then(res => {
          this.getListingAndResetPagination();
        })
        .catch(err => {
          this.$captureError(err);
        });
    },

    destinationCompleted(service) {
      const data = {};
      data.serviceId = service.id;
      if (
        service.destinations &&
        service.destinationNo < service.destinations.length
      ) {
        const dest = service.destinations.find(
          d => d.order === service.destinationsDeliveredTo
        );
        data.destinationId = dest ? dest.id : null;
      }
      Services.destinationCompleted(data)
        .then(() => {
          this.getListingAndResetPagination();
        })
        .catch(err => {
          this.$captureError(err);
        });
    },

    handleNextAction(service) {
      switch (service.progressId) {
        case Progress.NUM_ACCEPTED:
          this.destinationArrived(service);
          return;
        case Progress.NUM_IN_COLLECTION:
          this.destinationCompleted(service);
          return;
        case Progress.NUM_IN_ROUTE:
        case Progress.NUM_IN_ROUTE_TO_NEXT_POINT:
        case Progress.NUM_IN_DELIVERY:
          this.handleNextDelivery(service);
          return;
      }
    },

    async handleNextDelivery(service) {
      await this.destinationArrived(service);
      this.destinationCompleted(service);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-date {
  min-width: 180px;
}

.container-multipoint-service {
  .vehicle {
    font-size: 24px;
  }
}
</style>
