<template>
  <z-dropdown
    ref="driversdd"
    search
    boundary="window"
    :options="driversOptions"
    :loading="loading"
    :max-height="maxHeight"
    :name="name"
    :no-caret="!value"
    :value="value"
    :variant="variant"
    :search-placeholder="searchPlaceholder"
    v-bind="$attrs"
    @hidden="submitDriver"
    @input="$emit('input', $event)"
    @search-change="searchDebounce"
    @shown="searchDebounce"
  >
    <template v-if="!value" #button-content>
      <div class="d-flex align-items-center assign-button">
        <font-awesome-icon icon="plus" class="icon" />
        <span>Asignar mensajero</span>
      </div>
    </template>
  </z-dropdown>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import DriverStatus from "@/constants/drivers/status";
import Drivers from "@/services/drivers";
import _debounce from "lodash/debounce";

export default {
  name: "ZDropdownDrivers",
  components: { ZDropdown },

  props: {
    firstOption: {
      type: [Object, Boolean],
      default: () => ({ text: "Todos", value: null })
    },
    driverStatus: {
      type: [Array],
      default: () => [DriverStatus.NUM_ACTIVE]
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: null
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: "Buscar mensajero"
    },
    serviceId: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    }
  },

  data() {
    return {
      innerValue: this.value,
      loading: false,
      driversOptions: this.firstOption ? [this.firstOption] : []
    };
  },

  computed: {
    hasCurrentOption() {
      return this.driversOptions.find(op => this.value === op?.value);
    },
    selectedCity() {
      return this.$store.getters["cities/getCity"];
    }
  },

  watch: {
    value() {
      if (!this.hasCurrentOption) {
        this.getDriversOptions();
      }
    },
    selectedCity() {
      this.getDriversOptions();
    }
  },

  created() {
    this.searchDebounce = _debounce(searchText => {
      // Prevent the endpoint to be called after options
      // have been loaded
      if (searchText || this.driversOptions.length <= 1) {
        this.getDriversOptions(searchText);
      }
    }, 600);
  },

  methods: {
    submitDriver() {
      this.$emit("input", this.innerValue);
      this.hide();
    },
    hide() {
      this.$refs.driversdd.hide();
    },
    selectDriver(option) {
      this.innerValue = option.value;
    },
    getDriversOptions(searchText = "") {
      if (!this.isMenuOpen()) return;
      this.loading = true;
      let where = {
        like: searchText,
        status: this.driverStatus
      };
      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }
      if (!searchText && !this.hasCurrentOption) {
        searchText = this.value;
      }
      if (this.exclude != null) {
        where = { ...where, id: { nin: this.exclude } };
      }
      searchText = searchText ? searchText.toLowerCase() : searchText;
      Drivers.nameListing({
        filter: { skip: 0, limit: 5, order: "lastLogin DESC" },
        where
      })
        .then(res => {
          if (
            this.firstOption &&
            !res.data.find(el => el.id === this.firstOption.value)
          ) {
            this.driversOptions = [this.firstOption].concat(
              res.data.map(driver => ({
                value: driver.id,
                text: driver.name
              }))
            );
          } else {
            this.driversOptions = res.data.map(driver => ({
              value: driver.id,
              text: driver.name
            }));
          }

          if (!this.hasCurrentOption && this.value) {
            this.getSelectedDriver();
          }
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSelectedDriver() {
      this.loading = true;
      Drivers.findById({
        id: this.value
      })
        .then(res => {
          this.driversOptions.push({ value: res.id, text: res.name });
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isMenuOpen() {
      if (this.$refs.driversdd) {
        return this.$refs.driversdd.$el.querySelector(".dropdown-menu.show")
          ? true
          : false;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.z-dropdown {
  .z-wrap-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .assign-button {
    color: $zubut-blue;
    .icon {
      font-size: 16px;
      margin-right: 6px;
    }
  }
}
</style>
