<template>
  <div
    class="z-kanban d-flex justify-content-between w-100"
    :class="{ loading }"
  >
    <div class="d-flex flex-column flex-md-row pt-3 w-100">
      <div
        v-for="(column, index) in columns"
        :key="column.title"
        class="column rounded-lg pb-3 column-width rounded mr-3"
      >
        <div
          class="column-title px-3 py-3 text-lg text-gray-700 font-weight-bold"
        >
          <slot name="title" :title="column.title">
            {{ column.title }}
          </slot>
        </div>
        <div class="column-content">
          <draggable
            :id="`z-kanban-column-${index}`"
            :list="column.items"
            :animation="200"
            group="items"
            class="px-3 h-100"
            :move="moveCallback"
          >
            <slot v-for="item in column.items" name="item" :data="item"></slot>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "ZKanban",
  components: {
    draggable
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    moveCallback: {
      type: Function,
      default: function() {}
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables/colors.scss";
.z-kanban {
  height: 80vh;
  position: relative;

  &.loading .column::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($solitude, 0.6);
  }
}
.column {
  background-color: $solitude;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.column-title {
  position: sticky;
  top: 0;
  left: 0;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  height: 50px;
  background-color: $solitude;
}

// This sets the are where the card is droppable
.column-content {
  height: 95%;
  width: 100%;
  overflow-y: scroll;
}

@include media-breakpoint-up(md) {
  .column {
    background-color: $solitude;
    width: 25%;
  }
}

@media only screen and (min-height: 1000px) {
  .z-kanban {
    height: 84vh;
  }
}
</style>
