<template>
  <div>
    <active-services-header
      title="Rutas"
      :service-type="serviceType"
      :loading.sync="loading"
      :has-updates.sync="hasUpdates"
      @refresh="getServices()"
    />

    <b-row class="mt-4">
      <b-col lg="3">
        <z-search-input
          v-model="searchTextInput"
          placeholder="Buscar por fecha, cliente, mensajero"
        />
      </b-col>
      <b-col lg="3" class="mt-3 mt-lg-0">
        <z-dropdown-check-list-clients v-model="clientSelectedValue" />
      </b-col>
      <b-col lg="3" class="mt-3 mt-lg-0">
        <z-dropdown
          id="dropdown-date"
          v-model="vehicleSelectedValue"
          :options="vehicleOptions"
          name="Vehículo"
        />
      </b-col>
    </b-row>

    <div class="mt-4">
      <z-table
        hover
        primary-key="id"
        :items="services"
        :fields="fields"
        :busy="loading"
        @row-clicked="viewServiceDetail"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <loading-spinner />
          </div>
        </template>
        <!-- slot - empty message -->
        <template v-slot:empty>
          <p class="text-center py-5 my-5 font-weight-semi-bold">
            No hay servicios de renta por hora activos
          </p>
        </template>
        <!-- slot - header -->
        <template v-slot:head(createdAt)>
          <z-table-head-sort :order.sync="dateOrder" class="header-date">
            Fecha
          </z-table-head-sort>
        </template>
        <template v-slot:head(vehicleType)>
          <div>
            <div class="d-lg-none">
              Vehículo
            </div>
            <z-table-header-dropdown
              id="dropdown-vehicle-type"
              v-model="vehicleSelectedValue"
              class="d-none d-lg-block"
              button-class="text-center"
              :options="vehicleOptions"
            />
          </div>
        </template>
        <template v-slot:head(cost)>
          <z-table-head-sort :order.sync="costOrder" class="header-date">
            Costo
          </z-table-head-sort>
        </template>
        <!-- slot cells -->
        <template v-slot:cell(status)="row">
          <status-service :status="row.item.status" :combined="false" />
        </template>
        <template v-slot:cell(delivered)="row">
          <div>
            {{ row.item.additional.deliveries }} de
            {{ row.item.additional.attempts + row.item.additional.deliveries }}
          </div>
        </template>
        <template v-slot:cell(vehicleType)="row">
          <span>
            <font-awesome-icon
              class="vehicle"
              :icon="vehicle(row.item.vehicleType)"
              size="2x"
            />
          </span>
        </template>
        <template v-slot:cell(actions)="{ item: service }">
          <service-actions
            :id="service.id"
            :status="service.status"
            :archived="service.archived"
            :payment-status="service.paymentStatus"
            :type-service="service.type"
            :driver-id="service.driverId"
            :share-service="false"
            @approve-pending="dispatchApproveModal(true, service.id)"
            @deny-pending="dispatchApproveModal(false, service.id)"
            @archive="dispatchArchive(service.id)"
          />
        </template>
      </z-table>
      <z-table-pagination
        :total-rows="totalServicesCount"
        :per-page.sync="perPage"
        :current-page.sync="currentPage"
      />
    </div>
    <confirmation-dialog
      :show="showApproveModal"
      @accept="dispatchApprove(selectedServiceIdModal, true)"
      @cancel="hideApproveModal()"
      @hidden="hideApproveModal()"
    >
      ¿Seguro que deseas aprovar el servicio
      <b>{{ selectedServiceIdModalFormatted }}</b> ?
    </confirmation-dialog>
    <confirmation-dialog
      :show="showRejectModal"
      @accept="dispatchApprove(selectedServiceIdModal, false)"
      @cancel="hideRejectModal()"
      @hidden="hideRejectModal()"
    >
      ¿Seguro que deseas rechazar el servicio
      <b>{{ selectedServiceIdModalFormatted }}</b> ?
    </confirmation-dialog>
  </div>
</template>

<script>
import ActiveServicesHeader from "./ActiveServicesHeader";
import ServiceActions from "./ServiceActions";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import StatusService from "@zubut/common/src/components/StatusService";
import ServiceType from "@zubut/common/src/constants/services/type";
import VehiclesTypes from "@/constants/drivers/vehicles";
import ServiceAccess from "@/constants/access/services";
import ServiceStatus from "@/constants/services/status";
import VehicleType from "@/constants/vehicles/type";
import { makeShortUUID, moneyFormatter } from "@/utils/strings";
import filtering from "@/mixins/filtering";
import { formatISO } from "@zubut/common/src/utils/time";
import { mapGetters, mapMutations, mapActions } from "vuex";
import _debounce from "lodash/debounce";

export default {
  name: "ServiceCenterRoutes",

  components: {
    ActiveServicesHeader,
    ZDropdown,
    ZDropdownCheckListClients,
    ZSearchInput,
    ZTable,
    ZTableHeaderDropdown,
    ZTablePagination,
    ZTableHeadSort,
    StatusService,
    ServiceActions,
    ConfirmationDialog
  },

  mixins: [filtering("serviceCenter")],
  data() {
    return {
      loading: false,
      serviceType: ServiceType.NUM_PACKAGE_DELIVERY,
      vehicleOptions: [{ text: "Todos", value: null }].concat(
        VehiclesTypes.options
      ),
      dateOrder: "asc",
      costOrder: "asc",
      orderFilter: "createdAt asc",
      showApproveModal: false,
      showRejectModal: false,
      selectedServiceIdModal: "",
      fields: [
        { key: "id", label: "ID", formatter: val => makeShortUUID(val) },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val =>
            val ? formatISO(val, "dd 'de' MMM',' yyyy p aaa") : ""
        },
        { key: "user", label: "Cliente" },
        { key: "driver", label: "Mensajero" },
        { key: "status", label: "Estado" },
        { key: "delivered", label: "Entregados" },
        { key: "vehicleType", label: "", class: "text-center" },
        { key: "cost", label: "Costo", formatter: val => moneyFormatter(val) },
        { key: "actions", label: "Acciones", class: "text-right" }
      ]
    };
  },

  computed: {
    ...mapGetters("serviceCenter/route", [
      "services",
      "clientSelected",
      "vehicleSelected",
      "searchText",
      "totalServicesCount",
      "getHasUpdates"
    ]),

    ...mapGetters("clients", ["clientsOptions"]),

    selectedCity() {
      return this.$store.getters["cities/getCity"];
    },

    selectedServiceIdModalFormatted() {
      return makeShortUUID(this.selectedServiceIdModal);
    },

    clientSelectedValue: {
      get() {
        return this.clientSelected;
      },
      set(value) {
        this.clientFilterChanged(value);
      }
    },

    vehicleSelectedValue: {
      get() {
        return this.vehicleSelected;
      },
      set(value) {
        this.setFilter("vehicleType", value);
        this.vehicleFilterChanged(value);
      }
    },

    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.setFilter("search", value);
        this.searchTextChanged(value);
      }
    },

    hasUpdates: {
      get() {
        return this.getHasUpdates;
      },
      set(val) {
        this.toggleUpdateIndicator(val);
      }
    }
  },

  watch: {
    clientSelectedValue: "getServices",
    selectedCity: "getServices",
    dateOrder(value) {
      this.orderFilter = `createdAt ${value}`;
      this.getServices();
    },
    costOrder(value) {
      this.orderFilter = `estimatedRate ${value}`;
      this.getServices();
    }
  },

  created() {
    this.setRouteFilters();
    this.loading = true;
    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getServices(), 100);
    this.searchFilterListener = _debounce(() => {
      this.getServices();
    }, 600);
  },

  mounted() {
    this.hasUpdates = false;
  },

  methods: {
    ...mapMutations("serviceCenter/route", [
      "clientFilterChanged",
      "vehicleFilterChanged",
      "searchTextChanged",
      "toggleUpdateIndicator"
    ]),

    ...mapActions("serviceCenter/route", [
      "getRouteServices",
      "review",
      "archive"
    ]),

    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab || "multi-punto";
      const page = this.currentPage;
      const show = this.perPage;
      const search = this.searchTextInput;
      const vehicleType = this.vehicleSelectedValue;

      query.tab = query.tab ? query.tab : tab;
      query.search = query.search ? query.search : search;
      query.vehicleType = query.vehicleType
        ? query.vehicleType
        : vehicleType || query.vehicleType;
      query.page = query.page ? query.page : page;
      query.show = query.show ? query.show : show;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});

      this.searchTextInput = query.search ?? "";
      this.vehicleSelectedValue = query.vehicleType ?? null;
    },

    hideApproveModal() {
      this.selectedServiceIdModal = "";
      this.showApproveModal = false;
    },

    hideRejectModal() {
      this.selectedServiceIdModal = "";
      this.showRejectModal = false;
    },

    dispatchApproveModal(approve, serviceId) {
      if (approve) {
        this.showApproveModal = true;
      } else {
        this.showRejectModal = true;
      }
      this.selectedServiceIdModal = serviceId;
    },

    buildRequest() {
      let filter = {
        ...this.pagination,
        order: this.orderFilter
      };
      let where = {
        like: this.searchTextInput,
        status: ServiceStatus.NUM_REVIEW_PENDING,
        type: ServiceType.NUM_PACKAGE_DELIVERY,
        archived: false
      };

      if (this.clientSelectedValue.length > 0) {
        where.clientId = { inq: this.clientSelectedValue };
      }

      if (this.vehicleSelectedValue != null) {
        where.vehicleType = this.vehicleSelectedValue;
      }

      /* Set city filter */
      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }

      return { filter, where };
    },

    getServices() {
      const { filter, where } = this.buildRequest();
      this.loading = true;
      this.getRouteServices({ filter, where }).finally(() => {
        this.loading = false;
      });
    },

    dispatchApprove(id, approve) {
      this.loading = true;
      this.hideApproveModal();
      this.hideRejectModal();
      this.review({ id, approve }).finally(() => {
        this.loading = false;
        this.getServices();
      });
    },

    dispatchArchive(id) {
      this.loading = true;
      this.archive({ serviceId: id }).finally(() => {
        this.loading = false;
        this.getServices();
      });
    },

    vehicle(type) {
      switch (type) {
        case VehicleType.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleType.NUM_CAR:
          return "zubut-car";
        case VehicleType.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    viewServiceDetail(service) {
      if (
        this.$can(ServiceAccess.READ_SERVICE_DETAIL, ServiceAccess.moduleName)
      ) {
        this.$router.push({
          name: "serviceDetail",
          params: { id: service.id }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-date {
  min-width: 180px;
}

.header-client,
.header-branch {
  min-width: 100px;
}
</style>
