<template>
  <div>
    <div v-show="hasAlert" data-test-id="container">
      <div
        v-show="defineAlertIncidence"
        v-tooltip="'Incidencias'"
        class="table-alert active"
      >
        <font-awesome-icon icon="incidence" />
      </div>
      <div
        v-show="defineAlertDriverUnassigned"
        v-tooltip="'Sin mensajero'"
        class="table-alert active"
      >
        <font-awesome-icon icon="unassigned" />
      </div>
    </div>
    <div
      v-show="!hasAlert"
      data-test-id="empty-container"
      class="table-alert desactive"
    />
  </div>
</template>

<script>
export default {
  name: "TableAlert",

  props: {
    driverId: {
      type: String,
      default: null
    },
    hasIncidence: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    hasAlert() {
      return !this.driverId || this.hasIncidence;
    },
    defineAlertIncidence() {
      return this.driverId && this.hasIncidence;
    },
    defineAlertDriverUnassigned() {
      return !this.driverId;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;

  &.active {
    background-color: rgba($tangerine-yellow, 0.12);
    color: $gamboge;
  }

  &.desactive {
    background-color: none;
  }
}
</style>
