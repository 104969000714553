<template>
  <b-modal
    id="service-creation-modal"
    ref="serviceCreationModal"
    size="md"
    centered
    hide-header
    hide-footer
  >
    <b-row class="model-container">
      <b-col cols="12">
        <h1>Nuevo servicio</h1>
      </b-col>
      <b-col md="4">
        <div class="icon-container" @click="createService('renta-hora')">
          <servicios-por-hora-icon class="service-reservation-icon" />
        </div>
        <p>Renta por hora</p>
      </b-col>
      <b-col md="4">
        <div class="icon-container" @click="createService('express')">
          <servicios-multi-punto-icon class="service-multipoint-icon" />
        </div>
        <p>Express</p>
      </b-col>
      <b-col md="4">
        <div class="icon-container" @click="createService('entregados')">
          <servicios-entregados-icon class="service-delivered-icon" />
        </div>
        <p>Entregados</p>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ServiciosMultiPuntoIcon from "@/assets/img/servicios-multi-punto.svg";
import ServiciosEntregadosIcon from "@/assets/img/entregados2.svg";
import ServiciosPorHoraIcon from "@/assets/img/servicios-renta-hora.svg";

export default {
  components: {
    ServiciosEntregadosIcon,
    ServiciosPorHoraIcon,
    ServiciosMultiPuntoIcon
  },
  methods: {
    createService(serviceType) {
      if (serviceType === "express") {
        this.$router.push({
          name: "expressCreation"
        });
      } else {
        this.$router.push({
          name: "serviceCreation",
          query: { serviceType }
        });
      }
      this.$root.$emit(
        "bv::hide::modal",
        "service-creation-modal",
        "#create-service-btn"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#service-creation-modal {
  .model-container {
    padding: 1.5rem;
  }

  .icon-container {
    cursor: pointer;
    border-radius: 2px;
    width: 8rem;
    height: 8rem;
    background-color: $solitude;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &:hover {
      background-color: $gainsboro;
    }
  }

  .service-reservation-icon {
    width: 80%;
    height: 80%;
  }

  .service-multipoint-icon {
    width: 90%;
    height: 90%;
  }

  .service-delivered-icon {
    width: 60%;
    height: 60%;
  }

  h1 {
    color: $nero;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  p {
    text-align: center;
    font-size: 14px;
    color: $nero;
    margin-top: 8px;
  }
}
</style>
