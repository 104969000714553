import { render, staticRenderFns } from "./ServiceCenterMultipoint.vue?vue&type=template&id=286eb11c&scoped=true&"
import script from "./ServiceCenterMultipoint.vue?vue&type=script&lang=js&"
export * from "./ServiceCenterMultipoint.vue?vue&type=script&lang=js&"
import style0 from "./ServiceCenterMultipoint.vue?vue&type=style&index=0&id=286eb11c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286eb11c",
  null
  
)

export default component.exports