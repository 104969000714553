<template>
  <div class="header-container">
    <div
      class="title-container d-flex flex-row align-items-center justify-content-start"
    >
      <slot name="avatar">
        <service-type-avatar :type="serviceType" class="mr-3" size="lg" />
      </slot>
      <div id="title">{{ title }}</div>
    </div>
    <div class="d-flex">
      <slot name="refresh-button">
        <z-button-refresh
          v-if="withRefresh"
          data-test-id="refresh-button"
          :loading="loading"
          :has-updates="hasUpdates"
          :variant="refreshButtonVariant"
          @refresh="refresh"
        />
      </slot>
      <slot name="end"> </slot>
    </div>
  </div>
</template>

<script>
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh";

export default {
  name: "ActiveServicesHeader",

  components: {
    ServiceTypeAvatar,
    ZButtonRefresh
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    serviceType: {
      type: Number,
      required: false,
      default: null
    },
    withRefresh: {
      type: Boolean,
      default: true
    },
    hasUpdates: {
      type: Boolean,
      default: false
    },
    refreshButtonVariant: {
      type: String,
      default: "default",
      validator: val => ["default", "small"].indexOf(val) > -1
    }
  },

  computed: {
    loadText() {
      if (this.loading) return "Actualizando";
      return "Actualizar";
    }
  },

  methods: {
    refresh() {
      this.$emit("update:loading", true);
      this.$emit("update:hasUpdates", false);
      this.$emit("refresh");
    }
  }
};
</script>

<style lang="scss" scoped>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  flex-direction: column;
  & > div {
    margin-bottom: 1em;
  }
}

@media (min-width: 500px) {
  .title-container {
    width: 205px;
  }

  .header-container {
    flex-direction: row;
    justify-content: space-between;

    & > div {
      margin-bottom: 0em;
    }
  }
}
</style>
