















import Vue from "vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown.vue";
import DropdownOption from "@zubut/common/src/models/dropdown-option";
import * as mutation from "@/store/modules/cities/mutations-types";
import * as clientsMutationTypes from "@/store/modules/clients/mutations-types";
import * as driversMutationTypes from "@/store/modules/drivers/mutations-types";
import * as serviceCenterMutationTypes from "@/store/modules/serviceCenter/multipoint/mutations-types";
import * as transactionsMutationTypes from "@/store/modules/transactions/mutations-types";

export default Vue.extend({
  name: "ZDropdownCities",

  components: {
    ZDropdown
  },

  props: {
    maxHeight: {
      type: String,
      default: "400px"
    },
    name: {
      type: String,
      default: "Ciudad"
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: null
    },
    variant: {
      type: String,
      default: "default",
      validator: val => ["default", "white"].indexOf(val) > -1
    }
  },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    options(): DropdownOption[] {
      return this.$store.getters["cities/cityOptions"];
    },
    selectedCity: {
      get(): string | null {
        const cityId = this.$route.query.city
          ? this.$route.query.city
          : this.$store.getters[`cities/getCity`];

        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, cityId);
        return cityId;
      },
      set(val: string | null) {
        this.$store.commit(`cities/${mutation.SET_SELECTED_CITY}`, val);
        this.cleanStoreOnSelectedCityChange();
      }
    }
  },

  created() {
    this.getOptions();
  },

  methods: {
    cleanStoreOnSelectedCityChange() {
      this.$store.commit(
        `clients/${clientsMutationTypes.SET_CLIENTS_FILTER}`,
        []
      );
      this.$store.commit(
        `clients/${clientsMutationTypes.SET_CLIENTS_CHECKBOX_FILTER}`,
        []
      );
      this.$store.commit(
        `drivers/${driversMutationTypes.SET_DRIVERS_FILTER}`,
        []
      );

      this.$store.commit(
        `serviceCenter/multipoint/${serviceCenterMutationTypes.SET_FILTER_CLIENT}`,
        []
      );
      this.$store.commit(
        `transactions/${transactionsMutationTypes.UPDATE_LISTING_FILTER_CLIENT}`,
        []
      );
      this.$store.commit(
        `transactions/${transactionsMutationTypes.UPDATE_LISTING_FILTER_DRIVER}`,
        null
      );
    },
    getOptions() {
      this.loading = true;
      this.$store.dispatch("cities/getCities").finally(() => {
        this.loading = false;
      });
    }
  }
});
