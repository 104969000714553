<template>
  <div
    class="service-action d-flex align-items-center justify-content-end"
    :class="{
      'service-action-white': variant === 'white',
      'service-action-blue': variant === 'blue',
      'service-action-dark': variant === 'dark'
    }"
  >
    <z-tracking-link v-if="shareService" :id="id" shorten-url />
    <b-dropdown
      menu-class="service-action-dropdown"
      variant="link"
      size="md"
      no-caret
      dropleft
      lazy
    >
      <template v-slot:button-content>
        <font-awesome-icon icon="ellipsis-h" />
      </template>
      <slot name="extra-option" />
      <b-dropdown-item v-if="hasExtraOption" @click.stop="$emit('check-in')">
        <div class="d-flex align-items-center">
          <z-icon name="Move" size="18" class="mr-2" />
          Avanzar a En Ruta
        </div>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="finishService"
        @click.stop="$emit('finish-service')"
      >
        <div class="d-flex align-items-center">
          <z-icon name="CheckCircle" size="18" class="mr-2" />
          Finalizar Servicio
        </div>
      </b-dropdown-item>
      <b-dropdown-item v-if="archiveService" @click.stop="$emit('archive')">
        Archivar Servicio
      </b-dropdown-item>
      <b-dropdown-item v-if="restoreService" @click.stop="$emit('archive')">
        Restaurar Servicio
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isReviewPending"
        @click.stop="$emit('approve-pending')"
      >
        Aprobar Servicio
      </b-dropdown-item>
      <b-dropdown-item
        v-if="isReviewPending"
        @click.stop="$emit('deny-pending')"
      >
        Rechazar Servicio
      </b-dropdown-item>
      <hr class="my-0 mx-2" />
      <b-dropdown-item
        v-if="cancelService"
        @click.stop="$emit('cancel-service')"
      >
        <div class="d-flex align-items-center">
          <z-icon name="CancelCircle" size="18" class="mr-2" />
          Cancelar Servicio
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import ZTrackingLink from "@zubut/common/src/components/ZTrackingLink";
import ServiceType from "@zubut/common/src/constants/services/type";
import RoleType from "@/constants/roles/type.js";
import ServiceStatus from "@/constants/services/status";
import ServicePaymentStatus from "@/constants/services/payment-status.js";
import ReservationStatus from "@/constants/serviceCenter/status-reservation";
import Access from "@/constants/access";

export default {
  name: "ServiceActions",

  components: {
    ZTrackingLink
  },

  props: {
    id: {
      type: String,
      required: true
    },
    status: {
      type: Number,
      required: true
    },
    archived: {
      type: Boolean,
      required: true,
      default: false
    },
    typeService: {
      type: Number,
      required: true,
      default: -1
    },
    driverId: {
      type: String,
      default: null
    },
    shareService: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: "dark",
      validator: val => ["white", "blue", "dark"].indexOf(val) > -1
    }
  },

  data() {
    return {
      payment: ServicePaymentStatus,
      type: ServiceType,
      statusNo: ServiceStatus,
      access: Access,
      loading: false
    };
  },

  computed: {
    useRoleType() {
      return this.$store.state.auth.user.role.type;
    },
    finishService() {
      return (
        this.$can(
          this.access.services.FINISH_SERVICE,
          this.access.services.moduleName
        ) && this.status === this.statusNo.NUM_ACTIVE
      );
    },
    cancelService() {
      const validStatus = [
        this.statusNo.NUM_REQUESTING,
        this.statusNo.NUM_ACTIVE,
        this.statusNo.NUM_SCHEDULED,
        this.statusNo.NUM_DRIVERS_NOT_AVAILABLE
      ];
      return (
        this.$can(
          this.access.services.CANCEL_SERVICE,
          this.access.services.moduleName
        ) &&
        (validStatus.includes(this.status) ||
          (this.status === this.statusNo.NUM_CREATED &&
            (this.typeService === this.type.NUM_RENT_PER_HOUR ||
              this.driverId === null)))
      );
    },
    archiveService() {
      return (
        this.$can(
          this.access.services.ARCHIVE_SERVICE,
          this.access.services.moduleName
        ) &&
        !this.archived &&
        this.status !== this.statusNo.NUM_CREATED &&
        this.status !== this.statusNo.NUM_REQUESTING &&
        this.status !== this.statusNo.NUM_ACTIVE &&
        this.status !== this.statusNo.NUM_DRIVERS_NOT_AVAILABLE
      );
    },
    restoreService() {
      return this.archived;
    },
    canRestoreService() {
      return (
        this.useRoleType === RoleType.NUM_SUPER_ADMIN ||
        this.useRoleType === RoleType.NUM_OPERATIONS
      );
    },
    isReviewPending() {
      return this.status === ServiceStatus.NUM_REVIEW_PENDING;
    },
    hasExtraOption() {
      return this.status === ReservationStatus.NUM_SCHEDULED && this.driverId;
    }
  }
};
</script>

<style lang="scss">
.service-action {
  .service-action-dropdown {
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    border-radius: 6px;
    .dropdown-item {
      font-size: 12px;
      background-color: $white;
      color: $charcoal;
      padding: 0.8em 1em;

      &:hover {
        background-color: rgba($royal-blue, 0.06);
      }
    }
  }
}

.service-action.service-action-dark {
  .btn-link svg {
    color: $dim-gray;
  }
}

.service-action.service-action-white {
  .btn-link svg {
    color: $white;
  }
}

.service-action.service-action-blue {
  .btn-link svg {
    color: $dodger-blue;
  }
}
</style>
