const SCHEDULED = "Agendado";
const WITHOUT_DRIVER = "Sin mensajero";
const ACCEPTED = "Aceptado";
const RECOLECTED = "Recolectado";
const IN_ROUTE = "En ruta";
const RETURNED = "Retornado";

const NUM_SCHEDULED = 0;
const NUM_WITHOUT_DRIVER = 1;
const NUM_ACCEPTED = 2;
const NUM_RECOLECTED = 3;
const NUM_IN_ROUTE = 4;
const NUM_RETURNED = 5;

const get = {};
get[NUM_SCHEDULED] = SCHEDULED;
get[NUM_WITHOUT_DRIVER] = WITHOUT_DRIVER;
get[NUM_ACCEPTED] = ACCEPTED;
get[NUM_RECOLECTED] = RECOLECTED;
get[NUM_IN_ROUTE] = IN_ROUTE;
get[NUM_RETURNED] = RETURNED;

const options = [
  { value: NUM_SCHEDULED, text: SCHEDULED },
  { value: NUM_WITHOUT_DRIVER, text: WITHOUT_DRIVER },
  { value: NUM_ACCEPTED, text: ACCEPTED }
];

export default {
  get,
  options,
  SCHEDULED,
  WITHOUT_DRIVER,
  ACCEPTED,
  RECOLECTED,
  IN_ROUTE,
  RETURNED,
  NUM_SCHEDULED,
  NUM_WITHOUT_DRIVER,
  NUM_ACCEPTED,
  NUM_RECOLECTED,
  NUM_IN_ROUTE,
  NUM_RETURNED
};
