<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import differenceInMinutes from "date-fns/differenceInMinutes";
import StatusReservation from "@/constants/serviceCenter/status-reservation";
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import ServiceStatus from "@zubut/common/src/constants/services/status";

export default {
  name: "StatusServiceReservation",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      default: -1
    },
    driverId: {
      type: String,
      default: null
    },
    start: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    statusName() {
      if (this.status >= 0) {
        if (
          this.driverId === null &&
          !ServiceStatus.isCompletedStatus(this.status)
        ) {
          return StatusReservation.WITHOUT_DRIVER;
        }
        if (this.status === ServiceStatus.NUM_CREATED) {
          const diff = differenceInMinutes(new Date(this.start), new Date());
          if (diff > 0) {
            return StatusReservation.SCHEDULED;
          } else {
            return StatusReservation.LATE;
          }
        }
        if (this.status === ServiceStatus.NUM_ACTIVE) {
          return StatusReservation.IN_ROUTE;
        }
        return ServiceStatus.get[this.status];
      } else return "";
    },
    colorVariant() {
      const diff = differenceInMinutes(new Date(this.start), new Date());
      if (this.driverId === null) {
        return "red";
      }
      if (this.status === ServiceStatus.NUM_CREATED) {
        if (diff > 0) {
          return "yellow";
        }
        return "red";
      }
      if (this.status === ServiceStatus.NUM_ACTIVE) {
        return "green";
      }
      if (ServiceStatus.CANCELLED_STATUS.includes(this.status)) {
        return "red";
      }
      return "green";
    }
  },

  methods: {}
};
</script>
