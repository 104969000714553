var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"p-md-3"},[_c('service-center-header',{attrs:{"user":_vm.userEmail}}),_c('quick-message',{staticClass:"my-3",attrs:{"type":"error","with-icon":"","show":_vm.showConnectionStatus,"title":"Desconectado","message":"No podemos establecer la conexición, para actualizar la información, por favor recargue la página."}}),_c('b-tabs',{staticClass:"mt-3",attrs:{"content-class":"mt-3"}},[(
          _vm.$can(
            _vm.access.deliveryCenter.READ_EXPRESS_DELIVERIES,
            _vm.access.deliveryCenter.moduleName
          )
        )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeSection === 'multi-punto'},on:{"click":function($event){return _vm.changeTab('multi-punto')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:{ outdated: _vm.hasMultipointUpdates }},[_vm._v("Express")])]},proxy:true}],null,false,1177121085)},[_c('service-center-multipoint')],1):_vm._e(),(
          _vm.$can(
            _vm.access.deliveryCenter.READ_RESERVATIONS,
            _vm.access.deliveryCenter.moduleName
          )
        )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeSection === 'renta-hora'},on:{"click":function($event){return _vm.changeTab('renta-hora')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:{ outdated: _vm.hasReservationUpdates }},[_vm._v(" Renta por hora ")])]},proxy:true}],null,false,3042879215)},[_c('service-center-reservations')],1):_vm._e(),(
          _vm.$can(
            _vm.access.deliveryCenter.READ_ROUTE_DELIVERIES,
            _vm.access.deliveryCenter.moduleName
          )
        )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeSection === 'rutas'},on:{"click":function($event){return _vm.changeTab('rutas')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:{ outdated: _vm.hasRouteUpdates }},[_vm._v("Rutas")])]},proxy:true}],null,false,1251048954)},[_c('service-center-routes')],1):_vm._e(),(
          _vm.$can(
            _vm.access.warehouses.READ_WAREHOUSES,
            _vm.access.warehouses.moduleName
          ) &&
            _vm.$can(
              _vm.access.deliveryCenter.READ_SAME_NEXT_DAY_DELIVERIES,
              _vm.access.deliveryCenter.moduleName
            )
        )?_c('b-tab',{attrs:{"lazy":"","active":_vm.activeSection === 'guias'},on:{"click":function($event){return _vm.changeTab('guias')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:{ outdated: _vm.hasRouteUpdates }},[_vm._v("Guías")])]},proxy:true}],null,false,3192116790)},[_c('service-center-cities')],1):_vm._e()],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }