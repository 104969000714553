<template>
  <div class="container-reservation-service">
    <b-row align-h="between" class="mt-3">
      <b-col>
        <b-row>
          <b-col lg="4" class="d-flex">
            <service-type-avatar
              :type="serviceType.NUM_RENT_PER_HOUR"
              class="mr-3"
              size="lg"
            />
            <z-search-input
              v-model="searchText"
              placeholder="Busca por cliente, mensajero o ID"
              class="w-100"
            />
          </b-col>
          <b-col lg="2" class="mt-3 mt-lg-0">
            <z-dropdown-check-list-clients v-model="filterClient" />
          </b-col>
          <b-col v-show="isTableLayout" lg="2" class="mt-3 mt-lg-0">
            <z-dropdown
              id="dropdown-status"
              v-model="filterStatus"
              :options="statusOptions"
              name="Estado"
            />
          </b-col>
          <b-col lg="2" class="mt-3 mt-lg-0">
            <z-dropdown
              id="dropdown-vehicle-type"
              v-model="filterVehicleType"
              :options="vehicleTypeOptions"
              name="Vehículo"
              class="h-100"
            />
          </b-col>
          <b-col cols="auto" class="mt-3 mt-lg-0 d-flex">
            <z-button-refresh
              v-show="isKanbanLayout"
              variant="small"
              :text="reachedLimit ? 'No hay mas para hoy' : 'Cargar más'"
              load-text="Cargando"
              :loading="loadingMore"
              :disabled="reachedLimit"
              class="mr-3"
              @refresh="loadMore"
            />
            <z-button-refresh
              :loading="loading"
              :has-updates="hasUpdates"
              variant="small"
              @refresh="getReservationsAndResetPagination"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="auto">
        <layout-swtich :layout-type.sync="filterLayoutType" />
      </b-col>
    </b-row>
    <div class="mt-2">
      <quick-message
        class="mb-2"
        with-icon
        :show.sync="hasMessage"
        :message="message"
        :type="typeOfMessage"
      />
      <div v-show="isKanbanLayout">
        <reservations-kanban
          :loading="loading"
          @open-detail="viewServiceDetail"
          @cancel-service="showCancelServiceModal"
          @finish-service="dispatchComplete"
          @check-in="handleCheckIn"
        />
      </div>
      <div v-show="isTableLayout">
        <reservations-table
          :loading="loading"
          @open-detail="viewServiceDetail"
          @cancel-service="showCancelServiceModal"
          @finish-service="dispatchComplete"
          @check-in="handleCheckIn"
        />
      </div>
    </div>
    <confirmation-dialog
      :show="showConfirmationModal"
      @accept="dispatchCancel()"
      @cancel="hideModal()"
      @hidden="hideModal()"
    >
      ¿Seguro que deseas cancelar el servicio
      <b>{{ selectedServiceId }}</b> ?
    </confirmation-dialog>
  </div>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";
import ServiceAccess from "@/constants/access/services";
import ServiceType from "@zubut/common/src/constants/services/type";
import DriverVehicles from "@/constants/drivers/vehicles";
import ReservationStatus from "@/constants/serviceCenter/status-reservation";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import filtering from "@/mixins/filtering";
import * as mutation from "@/store/modules/serviceCenter/reservations/mutations-types.js";
import _debounce from "lodash/debounce";
import LayoutType from "@/constants/serviceCenter/layout-type";
import { parseQueryParam } from "@zubut/common/src/utils/strings";
import ReservationsKanban from "./ReservationsKanban";
import ReservationsTable from "./ReservationsTable";
import { getTime, addHours, subHours } from "date-fns";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import LayoutSwtich from "./LayoutSwtich";

const moduleName = "serviceCenter/reservations";

export default {
  name: "ReservationServices",

  components: {
    ConfirmationDialog,
    LayoutSwtich,
    ReservationsKanban,
    ReservationsTable,
    ServiceTypeAvatar,
    ZButtonRefresh,
    ZDropdown,
    ZDropdownCheckListClients,
    ZSearchInput
  },

  mixins: [filtering("serviceCenter")],

  data() {
    return {
      serviceAccess: ServiceAccess,
      serviceType: ServiceType,
      loading: false,
      showConfirmationModal: false,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      selectedService: null,
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        DriverVehicles.options
      ),
      statusOptions: [{ text: "Todos", value: null }].concat(
        ReservationStatus.options
      ),
      loadingMore: false,
      reachedLimit: false,
      LayoutType
    };
  },

  computed: {
    reservations() {
      return this.$store.getters[`${moduleName}/getReservations`];
    },
    stateFilters() {
      return this.$store.getters[`${moduleName}/getFilters`];
    },
    totalReservations() {
      return this.$store.getters[`${moduleName}/getTotalItems`];
    },
    selectedCity() {
      return this.$store.getters["cities/getCity"];
    },
    selectedServiceId() {
      if (this.selectedService && this.selectedService.id) {
        return makeShortUUID(this.selectedService.id);
      }
      return "";
    },
    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    },
    searchText: {
      get() {
        return this.stateFilters.search;
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_SEARCH}`, val);
      }
    },
    filterDate: {
      get() {
        return this.stateFilters.date;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_DATE}`,
          val
        );
      }
    },
    filterClient: {
      get() {
        return this.stateFilters.clientId;
      },
      set(val) {
        this.$store.commit(`${moduleName}/${mutation.SET_FILTER_CLIENT}`, val);
      }
    },
    filterVehicleType: {
      get() {
        return this.stateFilters.vehicleType;
      },
      set(val) {
        this.setFilter("vehicleType", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_VEHICLE_TYPE}`,
          val
        );
      }
    },
    filterSortBy: {
      get() {
        return this.stateFilters.sortBy;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_BY}`,
          val
        );
      }
    },
    filterSortOrder: {
      get() {
        return this.stateFilters.sortOrder;
      },
      set(val) {
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_SORT_ORDER}`,
          val
        );
      }
    },
    filterStatus: {
      get() {
        return this.stateFilters.status;
      },
      set(val) {
        this.setFilter("status", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_STATUS}`,
          val
        );
      }
    },
    filterFrom: {
      get() {
        return this.stateFilters.from;
      },
      set(val) {
        this.setFilter("from", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_FROM}`,
          val
        );
      }
    },
    filterUntil: {
      get() {
        return this.stateFilters.until;
      },
      set(val) {
        this.setFilter("until", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_UNTIL}`,
          val
        );
      }
    },
    filterLayoutType: {
      get() {
        return this.$store.getters[`${moduleName}/getLayoutType`];
      },
      set(val) {
        this.setFilter("layout", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_LAYOUT_TYPE}`,
          val
        );
      }
    },
    filterPage: {
      get() {
        return this.$store.getters[`${moduleName}/getPage`];
      },
      set(val) {
        // this.setFilter("page", val);
        return this.$store.commit(
          `${moduleName}/${mutation.SET_FILTER_PAGE}`,
          val
        );
      }
    },
    hasUpdates: {
      get() {
        return this.$store.getters[`${moduleName}/getHasUpdates`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.TOGGLE_UPDATE_INDICATOR}`,
          val
        );
      }
    },
    isKanbanLayout() {
      return this.filterLayoutType === LayoutType.NUM_KANBAN;
    },
    isTableLayout() {
      return this.filterLayoutType === LayoutType.NUM_TABLE;
    },
    kanbanPage() {
      return this.$store.getters[`${moduleName}/getPage`];
    },
    kanbanSkip() {
      return 10 * (this.kanbanPage - 1);
    }
  },

  watch: {
    selectedCity: "getReservationsAndResetPagination",
    filterDate: "getReservationsAndResetPagination",
    filterClient: "getReservationsAndResetPagination",
    kanbanPage() {
      if (this.kanbanPage === 1) {
        this.reachedLimit = false;
      }
    }
  },

  created() {
    this.setRouteFilters();
    this.loading = true;
    /* Using debounce avoids duplicate calls caused by watchers */
    this.defaultListener = _debounce(() => this.getReservations(), 100);
    this.searchFilterListener = _debounce(() => {
      this.getReservations();
    }, 600);
  },

  mounted() {
    this.hasUpdates = false;
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab || "renta-hora";
      const page = this.currentPage;
      const show = this.perPage;
      const layout = this.layout;
      const search = this.searchText;
      const status = this.filterStatus;
      const vehicleType = this.filterVehicleType;

      query.tab = query.tab ? query.tab : tab;
      query.search = query.search ? query.search : search;
      query.status =
        query.status || !status ? parseQueryParam(query.status) : status;
      query.vehicleType = query.vehicleType
        ? query.vehicleType
        : vehicleType || query.vehicleType;
      query.page = query.page ? parseQueryParam(query.page) : page;
      query.show = query.show ? parseQueryParam(query.show) : show;
      query.layout = query.layout ? parseQueryParam(query.layout) : layout;
      query.from = query.from
        ? parseQueryParam(query.from)
        : getTime(subHours(new Date(), 1));
      query.until = query.until
        ? parseQueryParam(query.until)
        : getTime(addHours(new Date(), 6));

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});

      this.searchText = query.search ?? "";
      this.filterStatus = query.status ?? null;
      this.filterVehicleType = query.vehicleType ?? null;
      this.filterLayoutType = query.layout ?? LayoutType.NUM_KANBAN;
      this.filterFrom = query.from ?? null;
      this.filterUntil = query.until ?? null;
    },

    getReservationsAndResetPagination() {
      this.paginationReset();
      this.getReservations();
    },

    getReservations() {
      this.loading = true;
      const params = {};

      if (this.isKanbanLayout) {
        params.pagination = { skip: 0, limit: 10 };
      } else {
        params.pagination = { ...this.pagination };
      }
      this.$store
        .dispatch(`${moduleName}/getReservations`, params)
        .then(() => {
          this.reachedLimit = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dispatchCancel() {
      this.hideModal();
      this.loading = true;
      const service = this.selectedService;
      const canceledService = {
        ...service,
        reason: 0
      };
      this.$store
        .dispatch(`${moduleName}/cancelService`, canceledService)
        .then(res => {
          this.isSuccessful = true;
          this.message =
            res?.response?.message || "Servicio cancelado correctamente";
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message = err?.message || "Error al cancelar el servicio";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showCancelServiceModal(service) {
      this.selectedService = service;
      this.showConfirmationModal = true;
    },

    hideModal() {
      this.showConfirmationModal = false;
    },

    paginationReset() {
      this.currentPage = 1;
    },

    viewServiceDetail(service) {
      if (
        this.$can(ServiceAccess.READ_SERVICE_DETAIL, ServiceAccess.moduleName)
      ) {
        this.$router.push({
          name: "serviceCenter-serviceDetail",
          params: { id: service.id },
          query: { tab: "renta-hora" }
        });
      }
    },

    dispatchComplete(service) {
      this.loading = true;
      this.$store
        .dispatch(`${moduleName}/completeService`, service)
        .then(res => {
          this.message = res?.data?.message || "Servicio finalizado con exito";
          this.isSuccessful = true;
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.message =
            err?.message ||
            "Occurrió un error al intentar finalizar el servicio";
          this.hasError = true;
          this.typeOfMessage = "error";
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleCheckIn(service) {
      const params = {};
      params.serviceId = service.id;
      params.driverId = service.driverId;
      this.$store
        .dispatch(`${moduleName}/checkInService`, params)
        .catch(err => {
          this.message =
            err?.message || "Ocurrió un error al intentar avanzar el servicio";
          this.hasError = true;
          this.typeOfMessage = "error";
        });
    },

    loadMore() {
      this.$store.commit(
        `${moduleName}/${mutation.SET_FILTER_PAGE}`,
        this.kanbanPage + 1
      );
      this.getAllKanbanReservations();
    },

    getAllKanbanReservations() {
      this.loadingMore = true;
      this.$store
        .dispatch(`${moduleName}/getKanbanReservations`, {
          pagination: { skip: this.kanbanSkip, limit: 10 }
        })
        .then(values => {
          this.reachedLimit =
            values?.every(({ meta }) => meta.length === 0) || true;
        })
        .finally(() => {
          this.loadingMore = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-reservation-service {
  .vehicle {
    font-size: 24px;
  }
}
</style>
