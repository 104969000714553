<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import StatusMultipoint from "@/constants/serviceCenter/status-multipoint";
import ServiceProgress from "@/constants/serviceCenter/progress";
import ServiceStatus from "@/constants/services/status";

export default {
  name: "StatusServiceMultipoint",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      default: -1
    },
    progressId: {
      type: Number,
      default: 0
    },
    driverId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      progressInRoute: [
        ServiceProgress.NUM_IN_ROUTE,
        ServiceProgress.NUM_IN_DELIVERY,
        ServiceProgress.NUM_IN_ROUTE_TO_NEXT_POINT
      ],
      progressReturn: [
        ServiceProgress.NUM_IN_ROUTE_TO_RETURN,
        ServiceProgress.NUM_IN_RETURN,
        ServiceProgress.NUM_RETURNED
      ]
    };
  },
  computed: {
    statusName() {
      if (this.status >= 0) {
        if (
          this.driverId === null &&
          (ServiceStatus.NUM_CREATED || ServiceStatus.NUM_REQUESTING)
        ) {
          return StatusMultipoint.WITHOUT_DRIVER;
        }
        if (this.status === ServiceStatus.NUM_ACTIVE) {
          if (this.progressId === ServiceProgress.NUM_ACCEPTED) {
            return StatusMultipoint.ACCEPTED;
          }
          if (this.progressId === ServiceProgress.NUM_IN_COLLECTION) {
            return StatusMultipoint.RECOLECTED;
          }
          if (this.progressInRoute.includes(this.progressId)) {
            return StatusMultipoint.IN_ROUTE;
          }
          if (this.progressReturn.includes(this.progressId)) {
            return StatusMultipoint.RETURNED;
          }
        }
        return ServiceStatus.get[this.status];
      } else return "";
    },
    colorVariant() {
      if (
        this.driverId === null &&
        (ServiceStatus.NUM_CREATED || ServiceStatus.NUM_REQUESTING)
      ) {
        return "red";
      }
      if (this.status === ServiceStatus.NUM_SCHEDULED) {
        return "yellow";
      }
      if (this.status === ServiceStatus.NUM_ACTIVE) {
        return "green";
      }
      return "green";
    }
  },

  methods: {}
};
</script>
