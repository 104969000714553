<template>
  <div>
    <active-services-header
      title="Guías"
      :loading.sync="loading"
      :with-refresh="false"
    >
      <template #avatar>
        <service-type-avatar
          class="mr-3"
          size="lg"
          content="G"
          color="#6655f6"
        />
      </template>
    </active-services-header>
    <b-row class="mt-4">
      <b-col lg="3">
        <z-search-input
          v-model="searchText"
          placeholder="Busca por ID, acrónimo o ciudad"
        />
      </b-col>
    </b-row>
    <z-table
      class="mt-4"
      responsive="sm"
      :items="cities"
      :fields="fields"
      :filter="searchText"
      :per-page="perPage"
      :current-page="currentPage"
      :loading="loading"
      @row-clicked="goToBatchDetail"
      @filtered="onFiltered"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template #emptyfiltered>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay ciudades registradas con este filtro
        </p>
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="rows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import ActiveServicesHeader from "../ActiveServicesHeader";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import filtering from "@/mixins/filtering";
import Cities from "@/services/cities";
import { makeShortUUID } from "@/utils/strings";
import * as mutation from "@/store/modules/cities/mutations-types";

const moduleName = "cities";

export default {
  name: "ServiceCenterCities",

  components: {
    ActiveServicesHeader,
    ServiceTypeAvatar,
    ZTablePagination,
    ZSearchInput,
    ZTable
  },

  mixins: [filtering("serviceCenter")],

  data() {
    return {
      loading: false,
      fields: [
        {
          key: "id",
          label: "ID",
          formatter: val => makeShortUUID(val),
          thClass: "thStyles"
        },
        {
          key: "abbreviation",
          label: "Acrónimo",
          thClass: "thStyles"
        },
        { key: "name", label: "Ciudad" }
      ],
      cities: [],
      rows: 1
    };
  },

  computed: {
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getCitiesFilterText`];
      },
      set(val) {
        this.setFilter("search", val);
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_CITIES_FILTER_TEXT}`,
          val
        );
      }
    }
  },

  created() {
    this.setRouteFilters();
  },

  mounted() {
    this.fetchAllCities();
  },

  methods: {
    setRouteFilters() {
      const query = { ...this.$route.query };
      const tab = this.$route.query.tab;
      const search = this.searchText;
      const page = this.currentPage;
      const show = this.perPage;

      query.tab = query.tab ? query.tab : tab;
      query.search = query.search ? query.search : search;
      query.page = query.page ? query.page : page;
      query.show = query.show ? query.show : show;

      this.searchText = query.search;

      this.$router
        .replace({
          name: this.$route.name,
          query
        })
        .catch(() => {});
    },
    fetchAllCities() {
      this.loading = true;
      Cities.find({})
        .then(cities => {
          this.cities = cities;
          this.rows = this.cities.length;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToBatchDetail(item) {
      const batchId = item.id;
      this.$router.push({
        name: "warehouseDetail",
        params: {
          id: batchId
        },
        query: {
          tab: "guias",
          name: item.name,
          abbreviation: item.abbreviation
        }
      });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .thStyles {
  width: 15%;
}
</style>
