const ACCEPTED = "Aceptado";
const IN_COLLECTION = "Recogiendo paquete";
const IN_ROUTE = "En camino";
const IN_DELIVERY = "Entregando paquete";
const IN_ROUTE_TO_NEXT_POINT = "En camino a siguiente destino";
const COMPLETED = "Servicio completado";
const IN_ROUTE_TO_RETURN = "En camino a regresar paquete";
const IN_RETURN = "Entregando paquete regresado";
const RETURNED = "Servicio completado con paquete regresado";

const NUM_ACCEPTED = 0;
const NUM_IN_COLLECTION = 1;
const NUM_IN_ROUTE = 2;
const NUM_IN_DELIVERY = 3;
const NUM_IN_ROUTE_TO_NEXT_POINT = 4;
const NUM_COMPLETED = 5;
const NUM_IN_ROUTE_TO_RETURN = 6;
const NUM_IN_RETURN = 7;
const NUM_RETURNED = 8;

const get = {};
get[NUM_ACCEPTED] = ACCEPTED;
get[NUM_IN_COLLECTION] = IN_COLLECTION;
get[NUM_IN_ROUTE] = IN_ROUTE;
get[NUM_IN_DELIVERY] = IN_DELIVERY;
get[NUM_IN_ROUTE_TO_NEXT_POINT] = IN_ROUTE_TO_NEXT_POINT;
get[NUM_COMPLETED] = COMPLETED;
get[NUM_IN_ROUTE_TO_RETURN] = IN_ROUTE_TO_RETURN;
get[NUM_IN_RETURN] = IN_RETURN;
get[NUM_RETURNED] = RETURNED;

export default {
  get,
  NUM_ACCEPTED,
  NUM_IN_COLLECTION,
  NUM_IN_ROUTE,
  NUM_IN_DELIVERY,
  NUM_IN_ROUTE_TO_NEXT_POINT,
  NUM_COMPLETED,
  NUM_IN_ROUTE_TO_RETURN,
  NUM_IN_RETURN,
  NUM_RETURNED
};
