<template>
  <div>
    <b-card class="p-md-3">
      <service-center-header :user="userEmail" />
      <quick-message
        class="my-3"
        type="error"
        with-icon
        :show="showConnectionStatus"
        title="Desconectado"
        message="No podemos establecer la conexición, para actualizar la información, por favor recargue la página."
      />
      <b-tabs class="mt-3" content-class="mt-3">
        <b-tab
          v-if="
            $can(
              access.deliveryCenter.READ_EXPRESS_DELIVERIES,
              access.deliveryCenter.moduleName
            )
          "
          lazy
          :active="activeSection === 'multi-punto'"
          @click="changeTab('multi-punto')"
        >
          <template #title>
            <span :class="{ outdated: hasMultipointUpdates }">Express</span>
          </template>
          <service-center-multipoint />
        </b-tab>
        <b-tab
          v-if="
            $can(
              access.deliveryCenter.READ_RESERVATIONS,
              access.deliveryCenter.moduleName
            )
          "
          lazy
          :active="activeSection === 'renta-hora'"
          @click="changeTab('renta-hora')"
        >
          <template #title>
            <span :class="{ outdated: hasReservationUpdates }">
              Renta por hora
            </span>
          </template>
          <service-center-reservations />
        </b-tab>
        <b-tab
          v-if="
            $can(
              access.deliveryCenter.READ_ROUTE_DELIVERIES,
              access.deliveryCenter.moduleName
            )
          "
          lazy
          :active="activeSection === 'rutas'"
          @click="changeTab('rutas')"
        >
          <template #title>
            <span :class="{ outdated: hasRouteUpdates }">Rutas</span>
          </template>
          <service-center-routes />
        </b-tab>
        <b-tab
          v-if="
            $can(
              access.warehouses.READ_WAREHOUSES,
              access.warehouses.moduleName
            ) &&
              $can(
                access.deliveryCenter.READ_SAME_NEXT_DAY_DELIVERIES,
                access.deliveryCenter.moduleName
              )
          "
          lazy
          :active="activeSection === 'guias'"
          @click="changeTab('guias')"
        >
          <template #title>
            <span :class="{ outdated: hasRouteUpdates }">Guías</span>
          </template>
          <service-center-cities />
        </b-tab>
      </b-tabs>
    </b-card>
    <router-view></router-view>
  </div>
</template>

<script>
import ServiceCenterHeader from "./ServiceCenterHeader";
import ServiceCenterCities from "./ServiceCenterCities/ServiceCenterCities";
import ServiceCenterReservations from "./ServiceCenterReservations/ServiceCenterReservations.vue";
import ServiceCenterMultipoint from "./ServiceCenterMultipoint";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceCenterRoutes from "./ServiceCenterRoutes.vue";
import Access from "@/constants/access";
import * as mutation from "@/store/modules/serviceCenter/mutations-types";

export default {
  name: "ServiceCenter",

  components: {
    ServiceCenterHeader,
    ServiceCenterRoutes,
    ServiceCenterMultipoint,
    ServiceCenterCities,
    ServiceCenterReservations
  },

  data() {
    return {
      access: Access,
      ServiceType
    };
  },

  computed: {
    activeSection: {
      get() {
        return this.$store.getters["serviceCenter/getSection"];
      },
      set(val) {
        this.$store.commit(`serviceCenter/${mutation.UPDATE_SECTION}`, val);
      }
    },

    userEmail() {
      return this.$store.state.auth.user.email;
    },

    hasMultipointUpdates() {
      return (
        this.$store.getters["serviceCenter/multipoint/getHasUpdates"] &&
        this.$route.query.tab !== "multi-punto"
      );
    },

    hasReservationUpdates() {
      return (
        this.$store.getters["serviceCenter/reservations/getHasUpdates"] &&
        this.$route.query.tab !== "renta-hora"
      );
    },

    hasRouteUpdates() {
      return (
        this.$store.getters["serviceCenter/route/getHasUpdates"] &&
        this.$route.query.tab !== "rutas"
      );
    },

    isReconnecting() {
      return this.$store.state.auth.isReconnecting;
    },

    isConnected() {
      return this.$store.state.auth.isConnected;
    },

    showConnectionStatus() {
      return (
        this.$can(
          Access.notifications.READ_NOTIFICATIONS,
          Access.notifications.moduleName
        ) &&
        (this.isReconnecting || !this.isConnected)
      );
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (from.query?.tab !== to.query?.tab) {
      this.activeSection = to.query.tab || "multi-punto";
    }
    next();
  },

  created() {
    const hasAvailableTab =
      this.$can(
        Access.deliveryCenter.READ_EXPRESS_DELIVERIES,
        Access.deliveryCenter.moduleName
      ) ||
      this.$can(
        Access.deliveryCenter.READ_RESERVATIONS,
        Access.deliveryCenter.moduleName
      ) ||
      this.$can(
        Access.deliveryCenter.READ_ROUTE_DELIVERIES,
        Access.deliveryCenter.moduleName
      ) ||
      (this.$can(
        Access.warehouses.READ_WAREHOUSES,
        Access.warehouses.moduleName
      ) &&
        this.$can(
          Access.deliveryCenter.READ_SAME_NEXT_DAY_DELIVERIES,
          Access.deliveryCenter.moduleName
        ));

    if (!hasAvailableTab) {
      this.$router.replace({
        name: "clients"
      });
      return;
    }
    if (!this.$route.query?.tab) {
      this.$router
        .replace({
          name: this.$route.name,
          query: {
            tab: "multi-punto"
          }
        })
        .then(() => {
          this.activeSection = "multi-punto";
        })
        .catch(() => {});
    } else {
      this.activeSection = this.$route.query.tab;
    }
    this.getDrivers();
  },

  methods: {
    getDrivers() {
      this.$store.dispatch("drivers/getListingDrivers");
    },
    changeTab(tab) {
      this.$router
        .push({ name: "serviceCenter", query: { tab } })
        .then(() => {
          this.activeSection = tab;
        })
        .catch(error => {
          this.$captureError(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.outdated {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -10px;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    border: 1px solid $white;
    background: $zubut-blue;
  }
}
</style>
