<template>
  <div>
    <z-dropdown-drivers
      id="dropdown-driver"
      v-model="selectedDriver"
      class="wrap-dropdown-driver"
      search
      :first-option="currentDriverOption"
      :service-id="serviceId"
    />
  </div>
</template>

<script>
import ZDropdownDrivers from "./ZDropdownDrivers";

export default {
  name: "AssignServiceDropdown",

  components: {
    ZDropdownDrivers
  },

  props: {
    currentDriver: {
      type: String,
      default: null
    },
    currentDriverName: {
      type: String,
      default: null
    },
    serviceId: {
      type: String,
      required: true
    },
    moduleName: {
      type: String,
      validator: value => {
        return (
          [
            "serviceCenter/reservations",
            "serviceCenter/multipoint",
            "serviceCenter/warehouses"
          ].indexOf(value) !== -1
        );
      },
      default: ""
    }
  },

  computed: {
    currentDriverOption() {
      if (this.currentDriver && this.currentDriverName) {
        return { value: this.currentDriver, text: this.currentDriverName };
      }
      return false;
    },

    selectedDriver: {
      get() {
        return this.currentDriver;
      },
      set(val) {
        if (val == null) return;
        if (this.currentDriver == null) {
          this.$store
            .dispatch(`${this.moduleName}/assignService`, {
              driverId: val,
              serviceId: this.serviceId
            })
            .then(res => {
              this.$emit("success", res);
            })
            .catch(err => {
              this.$emit("error", err);
            });
          return;
        }
        if (val !== this.currentDriver) {
          this.$store
            .dispatch(`${this.moduleName}/reassignService`, {
              driverId: val,
              serviceId: this.serviceId
            })
            .then(res => {
              this.$emit("success", res);
            })
            .catch(err => {
              this.$emit("error", err);
            });
          return;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.wrap-dropdown-driver {
  width: 160px;
}
</style>
