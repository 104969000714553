<template>
  <b-modal
    ref="confirmDialog"
    centered
    v-bind="$attrs"
    title-class="h4 font-weight-bold"
    hide-header-close
    header-class="pt-3"
    :show="show"
    :visible="show"
    :title="title"
    @hidden="hideModal"
  >
    <slot> </slot>
    <template v-slot:modal-footer class="w-100">
      <div>
        <z-button
          v-show="showAcceptButton"
          class="float-right ml-3"
          :loading="loading"
          :disabled="loading"
          data-test-id="accept-btn"
          @click="$emit('accept')"
        >
          {{ acceptText }}
        </z-button>
        <z-button
          variant="secondary"
          class="float-right btn-cancel"
          :disabled="loading"
          data-test-id="cancel-btn"
          @click="cancel"
        >
          {{ cancelText }}
        </z-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: {
    acceptText: {
      type: String,
      default: "Aceptar"
    },
    cancelText: {
      type: String,
      default: "Cancelar"
    },
    closeOnCancel: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Mensaje de confirmación"
    },
    show: {
      type: Boolean,
      default: false
    },
    showAcceptButton: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    show(newShow) {
      if (newShow === true) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    hideModal() {
      this.$emit("hidden");
      this.$emit("update:show", false);
      this.$refs.confirmDialog.hide();
    },
    showModal() {
      this.$emit("update:show", true);
      this.$refs.confirmDialog.show();
    },
    cancel() {
      this.$emit("cancel");

      if (this.closeOnCancel) {
        this.$emit("update:show", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  padding: 2rem 2rem 0rem 1rem;
}
</style>
